import React from "react";
import { Link } from "react-router";
import ReactVideo from "react.video";

class Section3 extends React.Component {
  render() {
    return (
      <div id="sectionThree">
        <h1>Team & Collaborators</h1>

        <div className="row secThreeRow">
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 secThreeMarBot">
            <div className="boxWhite">
              <div className="boxInline">
                <div className="boxImg">
                  <figure className="backEffectOne">
                    <img
                      src={require("../../img/homeAssets/wireFlow/faces/stefan.jpg")}
                      alt="Stefan Smiljkovic - Founder of Vanila.io"
                    />
                  </figure>
                </div>

                <div className="boxName">
                  <h3>Stefan Smiljkovic</h3>

                  <p>Founder of Vanila.io</p>

                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Serbia
                  </p>

                  <div className="commits">
                    <div className="firstCol">
                      <h3>6</h3>

                      <p>commits</p>
                    </div>

                    {/* 
                        <div className="secondCol">
                            <h3>41</h3>
                            <p>pulls</p>
                        </div> 
                    */}
                  </div>
                </div>
              </div>

              <div className="textBottom">
                <p>
                  Biz Dev, Growth Hacker, Founder of Automatio.co, Vanila.io,
                  Wireflow.co, Moon.ly, UXStore.com
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 secThreeMarBot">
            <div className="boxWhite">
              <div className="boxInline">
                <div className="boxImg">
                  <figure className="backEffectOne">
                    <img
                      src={require("../../img/homeAssets/wireFlow/faces/jelena.jpg")}
                      alt="Jelena Jovanovic - front end developer"
                    />
                  </figure>
                </div>

                <div className="boxName">
                  <h3>Jelena Jovanovic</h3>

                  <p>Front End Developer</p>

                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Serbia
                  </p>

                  <div className="commits">
                    <div className="firstCol">
                      <h3>30</h3>

                      <p>commits</p>
                    </div>

                    {/* <div className="secondCol">
            
        <h3>41</h3>
        
        <p>pulls</p>    
            
            
        </div> */}
                  </div>
                </div>
              </div>

              <div className="textBottom">
                <p>
                  Digital Maiden - Co-Founder &amp; Front End Developer at
                  Vanila.io
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 secThreeMarBot">
            <div className="boxWhite">
              <div className="boxInline">
                <div className="boxImg">
                  <figure className="backEffectOne">
                    <img
                      src={require("../../img/homeAssets/wireFlow/faces/tahy.jpg")}
                      alt="Abu Taher - Full Stack Developer"
                    />
                  </figure>
                </div>

                <div className="boxName">
                  <h3>Abu Taher</h3>

                  <p>Full Stack Developer</p>

                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Bangladesh
                  </p>

                  <div className="commits">
                    <div className="firstCol">
                      <h3>23</h3>

                      <p>commits</p>
                    </div>

                    {/* <div className="secondCol">
            
        <h3>41</h3>
        
        <p>pulls</p>    
            
            
        </div> */}
                  </div>
                </div>
              </div>

              <div className="textBottom">
                <p>
                  Wandering around programming world. Likes to experiment, learn
                  and teach. JavaScript lover.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 secThreeMarBot">
            <div className="boxWhite">
              <div className="boxInline">
                <div className="boxImg">
                  <figure className="backEffectOne">
                    <img
                      src={require("../../img/homeAssets/wireFlow/faces/lauri.jpg")}
                      alt="Lauri Ojansivu - Maintainer of Wekan"
                    />
                  </figure>
                </div>

                <div className="boxName">
                  <h3>Lauri Ojansivu</h3>

                  <p>Maintainer of Wekan</p>

                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Finland
                  </p>

                  <div className="commits">
                    <div className="firstCol">
                      <h3>4</h3>

                      <p>commits</p>
                    </div>

                    {/* <div className="secondCol">
            
        <h3>41</h3>
        
        <p>pulls</p>    
            
            
        </div> */}
                  </div>
                </div>
              </div>

              <div className="textBottom">
                <p>
                  Linux SysAdmin. On free time, Open Source code and translation
                  contributor. Maintainer of Wekan and TSC.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 secThreeMarBot">
            <div className="boxWhite">
              <div className="boxInline">
                <div className="boxImg">
                  <figure className="backEffectOne">
                    <img
                      src={require("../../img/homeAssets/wireFlow/faces/alur.jpg")}
                      alt="Raul Torrefiel"
                    />
                  </figure>
                </div>

                <div className="boxName">
                  <h3>Raul Torrefiel</h3>

                  <p>Software Engineer</p>

                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Philippines
                  </p>

                  <div className="commits">
                    <div className="firstCol">
                      <h3>105</h3>

                      <p>commits</p>
                    </div>

                    {/* <div className="secondCol">
            
        <h3>41</h3>
        
        <p>pulls</p>    
            
            
        </div> */}
                  </div>
                </div>
              </div>

              <div className="textBottom">
                <p>
                  Coder. Freelancer. Learning Constantly. Solving problems at
                  work. Likes growth hacking.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 secThreeMarBot">
            <div className="boxWhite">
              <div className="boxInline">
                <div className="boxImg">
                  <figure className="backEffectOne">
                    <img
                      src={require("../../img/homeAssets/wireFlow/faces/taqi.jpg")}
                      alt="Taqi Mustafa - Front End Developer"
                    />
                  </figure>
                </div>

                <div className="boxName">
                  <h3>Taqi Mustafa</h3>

                  <p>Front End Developer</p>

                  <p>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Pakistan
                  </p>

                  <div className="commits">
                    <div className="firstCol">
                      <h3>97</h3>

                      <p>commits</p>
                    </div>

                    {/* <div className="secondCol">
              
          <h3>41</h3>
          
          <p>pulls</p>    
              
              
          </div> */}
                  </div>
                </div>
              </div>

              <div className="textBottom">
                <p>
                  Helping startups in creating, maintaining, improving &amp;
                  growing their Ideas &amp; Apps.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section3;
